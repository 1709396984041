import Link from 'next/link';
import { signIn } from 'next-auth/react';
import { useState } from 'react';
import VideoBackupImage from './VideoBackupImage';
import BButton from '../BButton/BButton';

export default function Video({
  href,
  videoSrcDesktop,
  videoSrcMobile,
  videoAlt,
  imgSrc,
  imgAlt,
  user,
}) {
  let [isVideoClicked, setIsVideoClicked] = useState(null);
  const logoSrc =
    'https://res.cloudinary.com/musicfox/image/upload/v1616546651/b00st/development-assets/b00st-repo-image_slim_500_awisk6.png';
  if (typeof href !== 'undefined' && !!href) {
    return (
      <>
        {!user ? (
          <div className="mt-12 -mb-10 sm:-mb-10 lg:-mb-10 sm:mx-4 lg:mx-12">
            <span className="sr-only">{videoAlt}</span>
            <video
              className="hidden md:block rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              poster={imgSrc}>
              <source
                className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                type="video/mp4"
                alt={videoAlt}
                src={videoSrcDesktop}
              />
            </video>
            <span className="sr-only">{`A looped, soundless demo of B00ST ads, right from your email. It's so suave!`}</span>
            <video
              className="mx-auto md:hidden rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              autoPlay={true}
              width={300}
              muted={true}
              loop={true}
              playsInline={true}
              poster={imgSrc}>
              <source
                className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                type="video/mp4"
                alt={videoAlt}
                src={videoSrcMobile}
              />
            </video>
          </div>
        ) : (
          <div className="py-8">
            <BButton
              user={user}
              logoSrc={logoSrc}
              shape="square"
              open={isVideoClicked}
            />
          </div>
        )}
      </>
    );
  }
  return null;
}
