export default function DemoVideoSectionHeader({
  subtitle,
  title,
  titleDescription,
  titleSubHighlightDescription,
  titleSubNoHighlightDescription,
  callToActionText,
  callToActionEmail,
}) {
  if (typeof callToActionEmail !== 'undefined' && callToActionEmail !== null) {
    return (
      <div>
        <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">
          {subtitle}
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          {title}
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-700">
          {titleDescription}
        </p>
        <p className="mt-2 max-w-prose mx-auto text-md text-gray-600">
          <span className="text-blue-700">{titleSubHighlightDescription}</span>
          {` `}
          {titleSubNoHighlightDescription}
        </p>
        <p className="mt-3 max-w-1/2 italic text-sm text-gray-600">
          {callToActionText}{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${callToActionEmail}`}>
            <code className="text-blue-700 hover:text-black text-xs">
              {callToActionEmail}
            </code>
          </a>
        </p>
      </div>
    );
  }
  return null;
}
