import DemoVideoSectionHeader from '../DemoVideoSectionHeader/DemoVideoSectionHeader';
import Video from '../Video/Video';
import LazyLoad from 'react-lazyload';
const defaultVideoContent = {
  href: '/signup',
  videoSrcDesktop: 'images/b00st_demo_desktop.mp4',
  videoSrcMobile: 'images/b00st_demo_small.mp4',
  videoAlt:
    "A looped, soundless demo of tearsheetads autonomous ads for finance, advisors, and wealth managers. It's so suave!",

  imgSrc:
    'https://res.cloudinary.com/musicfox/image/upload/v1617808443/b00st/b00st.com/sm_re_w12q800x600_oonctq.svg',
  imgAlt: 'Social channel selection with autonomous cross-platform digital advertising software.',
};
const defaultVideoSectionContent = {
  subtitle: 'Reach more clients',
  title: 'Need more prospects? No Problem.',
  titleDescription: `We streamline your digital ads across platforms`,
  titleSubHighlightDescription: 'Maximize your budget',
  titleSubNoHighlightDescription: `and start with as little as $100 in ad spend.`,
  callToActionText: 'Press or click the Ad Button or drop us a note at',
  callToActionEmail: 'team@tearsheetads.com',
};
export default function DemoVideo({user, demoVideoContent}) {
  const videoContent =
    typeof demoVideoContent !== 'undefined' && !!demoVideoContent
      ? {...demoVideoContent, user}
      : {
        ...defaultVideoSectionContent,
        ...defaultVideoContent,
        user,
      };
  return (
    <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <DemoVideoSectionHeader {...videoContent} />
        <LazyLoad height={600} once offset={100}>
          <Video {...videoContent} />
        </LazyLoad>
      </div>
    </div>
  );
}
